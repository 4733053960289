<script setup lang="ts">
import placeHolderImage from '@/assets/svg/user-circle.svg';
import type { TransformedFollowingGroup } from '@/types/profile';

const profileStore = useProfileStore();
const userStore = useUserStore();
const notificationStore = useNotificationStore();

const { isMobile } = useWindowResize();

const currentPage = ref(profileStore.following.data?.paginationInfo.currentPage ?? 1);
const sortMethod = ref(FOLLOWING_SORTING_METHOD.ALPHABETICAL_A2Z);
const followerData = ref<TransformedFollowingGroup[]>([]);

const profileId = computed(() => profileStore.profileData?.id);
const isLoading = computed(() => profileStore.isLoading);
const paginationInfo = computed(() => profileStore.following.data?.paginationInfo);

const headers = computed(() => {
	if (isMobile.value) {
		return [
			{
				title: 'Organizer',
				align: 'start',
				key: 'name',
				sortable: false,
			},
			{
				title: 'Following',
				align: 'end',
				width: '104',
				key: 'isFollow',
				sortable: false,
			},
		];
	}
	return [
		{
			title: 'Organizer',
			align: 'start',
			key: 'name',
			sortable: false,
		},
		{
			title: 'Following since',
			align: 'start',
			width: '160',
			key: 'followedAt',
			sortable: false,
		},
		{
			title: 'Following',
			align: 'end',
			width: '160',
			key: 'isFollow',
			sortable: false,
		},
	];
});

const nameSortDirection = computed(() => {
	if (sortMethod.value === FOLLOWING_SORTING_METHOD.ALPHABETICAL_A2Z) {
		return ARROW_ICON_DIRECTION.DOWN;
	} else if (sortMethod.value === FOLLOWING_SORTING_METHOD.ALPHABETICAL_Z2A) {
		return ARROW_ICON_DIRECTION.UP;
	}
	return '';
});

const followDateSortDirection = computed(() => {
	if (sortMethod.value === FOLLOWING_SORTING_METHOD.OLDEST_FOLLOWED) {
		return 'down';
	} else if (sortMethod.value === FOLLOWING_SORTING_METHOD.LATEST_FOLLOWED) {
		return 'up';
	}
	return '';
});

async function handleUpdate() {
	if (profileId.value) {
		await profileStore.getFollowingList(profileId.value, {
			currentPage: currentPage.value,
		});

		followerData.value = profileStore.followingList;
	}
}

function handlePageChange(page: number) {
	currentPage.value = page;
	handleUpdate();
}

async function handleNameSort() {
	if (!profileId.value) {
		return;
	}

	if (sortMethod.value === FOLLOWING_SORTING_METHOD.ALPHABETICAL_Z2A) {
		sortMethod.value = FOLLOWING_SORTING_METHOD.ALPHABETICAL_A2Z;
	} else {
		sortMethod.value = FOLLOWING_SORTING_METHOD.ALPHABETICAL_Z2A;
	}

	await profileStore.getFollowingList(
		profileId.value,
		{
			currentPage: currentPage.value,
			sortBy: sortMethod.value,
		},
	);

	followerData.value = profileStore.followingList;
}

async function handleFollowDateSort() {
	if (!profileId.value) {
		return;
	}

	if (sortMethod.value === FOLLOWING_SORTING_METHOD.LATEST_FOLLOWED) {
		sortMethod.value = FOLLOWING_SORTING_METHOD.OLDEST_FOLLOWED;
	} else {
		sortMethod.value = FOLLOWING_SORTING_METHOD.LATEST_FOLLOWED;
	}

	await profileStore.getFollowingList(
		profileId.value,
		{
			currentPage: currentPage.value,
			sortBy: sortMethod.value,
		},
	);

	followerData.value = profileStore.followingList;
}

async function handleUpdatedFollowingStatus(groupId: number, isFollowing: boolean) {
	const successMessage = isFollowing ? 'Successfully unfollowed this organizer.' : 'Successfully followed this organizer.';

	if (!profileId.value || !groupId) {
		return;
	}

	if (isFollowing) {
		await userStore.unfollow(
			profileId.value,
			groupId,
		);
	} else {
		await userStore.follow(
			profileId.value,
			groupId,
		);
	}

	if (!profileStore.following.errorMessage) {
		notificationStore.showSuccessNotification(successMessage);
		const updatedFollowingData = followerData.value.map((organizer) => {
			if (organizer.id === groupId) {
				return {
					...organizer,
					isFollow: !isFollowing,
				};
			}

			return {
				...organizer,
			};
		});
		followerData.value = updatedFollowingData;
	}
}

onMounted(() => {
	// Reset the current page
	currentPage.value = 1;
	handleUpdate();
});
</script>

<template>
  <div class="following-container">
    <VDataTable
      class="following-table"
      density="compact"
      :show-current-page="false"
      :headers="(headers as any) || []"
      :items="followerData || []"
    >
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template #header.name="{ column }">
        <div
          v-if="isMobile"
          class="sort-column-wrapper"
        >
          <div
            class="sort-header"
            @click="handleNameSort"
          >
            <p>{{ column.title }}</p>
            <ArrowSortingIcon
              :direction="nameSortDirection"
            />
          </div>
          <p>/</p>
          <div
            class="sort-header"
            @click="handleFollowDateSort"
          >
            <p>Following since</p>
            <ArrowSortingIcon
              :direction="followDateSortDirection"
            />
          </div>
        </div>
        <div
          v-else
          class="sort-column-wrapper"
          @click="handleNameSort"
        >
          <p>{{ column.title }}</p>
          <ArrowSortingIcon
            :direction="nameSortDirection"
          />
        </div>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template #header.followedAt="{ column }">
        <div
          class="sort-column-wrapper"
          @click="handleFollowDateSort"
        >
          <p>{{ column.title }}</p>
          <ArrowSortingIcon
            :direction="followDateSortDirection"
          />
        </div>
      </template>
      <template #no-data>
        <template v-if="isLoading">
          <MultipleSkeletonLoader
            class="follower-loaders"
            rows="5"
            space="0"
            has-divider
          >
            <div class="follower-loader">
              <VSkeletonLoader
                class="avatar-loader"
                type="list-item-avatar"
              />
              <VSkeletonLoader
                type="list-item"
                width="120"
                height="56"
              />
              <ButtonSkeletonLoader
                width="108"
                height="56"
              />
            </div>
          </MultipleSkeletonLoader>
        </template>
        <template v-else>
          <div class="table-no-data">
            <SearchIcon
              class="icon"
              width="24"
              height="24"
            />
            <p class="header text-md text-semibold">
              Not following any organizers
            </p>
            <p class="text-sm text-regular">
              Organizers will be listed here once you follow them.
            </p>
          </div>
        </template>
      </template>
      <template #item="{ item }">
        <tr class="following-organizer text-sm text-medium">
          <template v-if="!isMobile">
            <td>
              <AvatarLink
                class="avatar-link"
                :image-url="item?.owner.avatar ?? placeHolderImage"
                :alt="item?.owner.nickname"
                :size="AvatarSizes[2]"
                :name="item?.owner.nickname"
                :user-id="item?.owner.id"
              >
                <p class="organizer-name">
                  {{ item?.owner.nickname || '' }}
                </p>
              </AvatarLink>
            </td>
            <td class="text-center">
              <p class="follow-date text-sm text-regular">
                {{ item?.followedAt }}
              </p>
            </td>
            <td class="text-right">
              <BaseButton
                v-if="item?.isFollow"
                class="follow-btn"
                size="sm"
                color="gray"
                variant="outlined"
                :disabled="isLoading || userStore.isLoading"
                @click="handleUpdatedFollowingStatus(item?.id, item?.isFollow)"
              >
                Following
              </BaseButton>
              <BaseButton
                v-else
                class="follow-btn"
                size="sm"
                :disabled="isLoading || userStore.isLoading"
                @click="handleUpdatedFollowingStatus(item?.id, item?.isFollow)"
              >
                Follow
              </BaseButton>
            </td>
          </template>

          <template v-else>
            <td class="text-center">
              <AvatarLink
                class="avatar-link"
                :image-url="item?.owner.avatar ?? placeHolderImage"
                :alt="item?.owner.nickname"
                :size="AvatarSizes[2]"
                :name="item?.owner.nickname"
                :user-id="item?.owner.id"
              />
              <div class="organizer-info">
                <RouterLink
                  class="organizer-name w-full"
                  :to="{
                    name: item?.id === profileId ? ROUTE.PROFILE.NAME : ROUTE.USER.NAME,
                    params: {
                      id: item?.owner.id,
                    },
                  }"
                >
                  <p class="text-left">
                    {{ item?.name }}
                  </p>
                </RouterLink>
                <p class="follow-date text-sm text-regular">
                  {{ item?.followedAt }}
                </p>
              </div>
            </td>
            <td>
              <BaseButton
                v-if="item?.isFollow"
                class="follow-btn"
                size="sm"
                color="gray"
                variant="outlined"
                :disabled="isLoading || userStore.isLoading"
                @click="handleUpdatedFollowingStatus(item?.id, item?.isFollow)"
              >
                Following
              </BaseButton>
              <BaseButton
                v-else
                class="follow-btn"
                size="sm"
                :disabled="isLoading || userStore.isLoading"
                @click="handleUpdatedFollowingStatus(item?.id, item?.isFollow)"
              >
                Follow
              </BaseButton>
            </td>
          </template>
        </tr>
      </template>
      <template #bottom>
        <BasePagination
          v-if="followerData?.length"
          :page="currentPage"
          :total-pages="paginationInfo?.totalPages"
          @on-page-change="handlePageChange"
        />
      </template>
    </VDataTable>
  </div>
</template>

<style lang="scss" scoped>
.following-container {
  .following-table {
    :deep(.v-table__wrapper) th:last-child div {
      margin-right: spacings-get(5);

      @include media-query-max(mobile) {
        margin-right: 0;
      }
    }
  }

  .following-organizer {
    display: grid;
    grid-template-columns: 100% rem(160) rem(160);
    grid-template-rows: 1fr;

    @include media-query-max(mobile) {
      grid-template-columns: 100% rem(104);
    }

    td {
      height: auto;
      min-height: rem(72);
      display: flex;
      align-items: center;

      @include media-query-max(mobile) {
        padding-right: 0 !important;
      }
    }

    .avatar-link {
      padding-right: spacings-get(3);
      color: colors-get(gray, 900);
    }

    .organizer-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: spacings-get(6);

      .organizer-name {
        color: colors-get(gray, 900);
      }
    }

    .follow-btn {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
  }

  .follower-loaders {
    :deep(.v-skeleton-loader__avatar) {
      width: spacings-get(10);
      height: spacings-get(10);
      min-width: spacings-get(10);
      min-height: spacings-get(10);
    }

    :deep(.v-skeleton-loader__bone) {
      height: inherit;
      max-width: inherit;
    }

    :deep(.v-skeleton-loader__list-item),
    :deep(.v-skeleton-loader__text) {
      height: rem(14);
      width: inherit;
      margin: 0;
    }

    :deep(.v-skeleton-loader__button) {
      height: rem(40);
      width: rem(108);
      margin: 0;
    }

    .follower-loader {
      width: 100%;
      display: grid;
      grid-template-columns: auto rem(160) rem(160);
      grid-template-rows: 5fr;
      align-items: center;
      justify-items: end;
      padding: spacings-get(4) rem(36) spacings-get(4) 0;

      .avatar-loader {
        width: inherit;
      }
    }
  }
}
</style>