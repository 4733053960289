<script setup lang="ts">

const props = defineProps({
	url: {
		type: String,
		default: '',
		required: true,
	},
	userId: {
		type: Number,
		default: null,
		required: true,
	},
	groupId: {
		type: Number,
		default: null,
		required: true,
	},
	eventId: {
		type: Number,
		default: null,
		required: false,
	},
});

const notificationStore = useNotificationStore();
const { trackEvent } = useGtm();

function handleCopyShareLink() {
	if (!props.url) {
		return;
	}

	copyToClipboard(props.url)
		.then(() => {
			notificationStore.showSuccessNotification('Successfully copied URL to clipboard.');

			trackEvent({
				event: props.eventId ? GTM_EVENT_NAME.SHARE_EVENT_SUCCESS : GTM_EVENT_NAME.SHARE_PROFILE_SUCCESS,
				user_id: props.userId,
				group_id: props.groupId,
				...(props.eventId ? { event_id: props.eventId } : {}),
			});
		})
		.catch(() => {
			notificationStore.showErrorNotification('Something went wrong. Please try again.');
		});
}
</script>

<template>
  <BaseButton
    variant="outlined"
    color="gray"
    size="sm"
    @click="handleCopyShareLink"
  >
    <ShareIcon />
    <slot />
  </BaseButton>
</template>